@font-face {
  font-family: "IBM Plex Sans", sans-serif;
}
.App {
  text-align: center;
  background: linear-gradient(#A2C9FF, #D8E9FF);
  min-height: calc(100vh - 120px);
  height: 100%;
  width: 100%;
  padding: 60px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-container {
  background: white;
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 24px 30px -12px rgba(59, 104, 158, 0.3);
  color: #7280A0;
  font-family: "IBM Plex Sans", sans-serif;
}

.upload-section {
  padding: 22px 36px;
}
.upload-section:not(:last-of-type) {
  border-bottom: solid #D5E1FF 1px;
}
.upload-section .output-files-title {
  margin: 0px 0px 12px 0px;
}

/*------ VERSION ------*/
.version {
  text-align: left;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
}
.version .version-numbers {
  display: flex;
  margin: 0px 14px;
}
.version .version-numbers .number {
  cursor: pointer;
  transition: 0.1s;
  padding: 3px 10px;
  border-radius: 3px;
  margin-right: 8px;
  border: 1px solid #D8E9FF;
}
.version .version-numbers .number:hover {
  background: #E7F1FF;
  box-shadow: 0px 2px 8px -2px rgba(216, 233, 255, 0.8);
  transform: translateY(-1px);
}
.version .version-numbers .number.active {
  background: #D8E9FF;
  color: #4E6496;
}

/*------ FILES ------*/
.files {
  text-align: left;
}
.files a {
  cursor: pointer;
  font-weight: 600;
  color: #4979FD;
}
.files .row {
  margin-top: 12px;
  display: flex;
}
.files .row section:not(:last-of-type) {
  margin-right: 8px;
}
.files .data-row {
  display: flex;
  flex-wrap: wrap;
}
.files .data-row section {
  width: 100%;
}
.files .data-row .data-added {
  width: 96px;
  min-width: 96px;
  height: 106px;
  word-break: break-word;
}
.files .data-row .data-added:not(:nth-of-type(4n)) {
  margin-right: 12px;
}
.files .data-row .data-added .upload-files-data {
  width: 88px;
  min-width: 88px;
  height: 86px;
  padding: 5px;
}
.files .data-row .data-added .upload-files-data .uploading-icon {
  animation: cloud-bounce 1s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes cloud-bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0px);
  }
}
.files .data-row .data-added .upload-files-data .added-file-text {
  font-size: 12px;
}
.files .clear {
  cursor: pointer;
  width: 100%;
  margin-top: 4px;
  background: blue;
  text-align: center;
  border-radius: 5px;
  padding: 6px 0px;
  background: #DFEDFF;
  transition: 0.1s;
}
.files .clear:hover {
  background: #CEE3FF;
  transition: 0.1s;
}

.add-files-box, .upload-files-data {
  position: relative;
  height: 100px;
  margin-bottom: 8px;
  min-width: 206px;
  max-width: 100%;
  width: auto;
  text-align: center;
  border: 1.5px dashed #CCD9F9;
  color: #5F7BBA;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-files-box:hover, .upload-files-data:hover {
  border-color: #77B5FF;
}
.add-files-box:focus, .upload-files-data:focus {
  outline: none;
}
.add-files-box p, .upload-files-data p {
  margin: 0px;
  font-size: 12pt;
  color: #707B94;
}
.add-files-box .icon, .add-files-box .x-icon, .upload-files-data .icon, .upload-files-data .x-icon {
  width: 22px;
  height: 22px;
}
.add-files-box .x-icon, .upload-files-data .x-icon {
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
}
.add-files-box .x-icon:hover + .x-circle, .upload-files-data .x-icon:hover + .x-circle {
  width: 26px;
  height: 26px;
  top: 2px;
  right: 2px;
  background: rgba(255, 255, 255, 0.3);
  transition: 0.1s;
}
.add-files-box .x-circle, .upload-files-data .x-circle {
  background: rgba(255, 255, 255, 0.1);
  width: 0px;
  height: 0px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: 0.1s;
  z-index: 1;
}
.add-files-box .upload-media-view, .upload-files-data .upload-media-view {
  padding: 0px 137px;
  min-width: 500px;
}
.add-files-box .data-files-sidenote, .upload-files-data .data-files-sidenote {
  font-size: 12px;
  margin-top: 2px;
  color: #7F8DAD;
}
.add-files-box .reject-text, .upload-files-data .reject-text {
  font-size: 15px;
  width: 160px;
  color: #B64D33;
}
.add-files-box .added-file-text, .upload-files-data .added-file-text {
  color: white;
  font-weight: 500;
}
.add-files-box .file-type-name, .upload-files-data .file-type-name {
  font-size: 12px;
  margin-top: 3px;
  letter-spacing: 1px;
}

.uploading-file {
  border: 1.5px dashed #CCD9F9 !important;
  background: rgba(216, 233, 255, 0.5) !important;
}
.uploading-file .uploading-icon, .uploading-file .added-file-text, .uploading-file .file-type-name {
  color: #7280A0;
}

.upload-files-active {
  border: 1.5px dashed #CCD9F9;
  background: rgba(216, 233, 255, 0.4);
}
.upload-files-reject {
  border: 1.5px dashed #FF9B82;
  background: rgba(255, 134, 105, 0.14);
}
.upload-files-added {
  cursor: default;
  border: 1.5px solid #28D4AB;
  background: #28D4AB;
  color: white;
}
.upload-files-added:hover {
  border-color: #00BC8F;
}
.upload-files-data {
  cursor: default;
  max-width: 60px;
  background: #2a8fff;
  border: 1.5px solid #2A8FFF;
  color: white !important;
  text-decoration: none;
}
.upload-files-data:hover {
  border-color: #0472EB;
}

/*------ SETTINGS ------*/
.settings {
  font-weight: 500;
}
.settings .MuiSlider-root {
  color: #6EF3D3;
  width: 370px;
}
.settings .MuiSlider-root .MuiSlider-rail {
  height: 20px;
  background: #E3EBFA;
  opacity: 1;
}
.settings .MuiSlider-root .MuiSlider-track {
  height: 20px;
  background: #05E3AD;
}
.settings .MuiSlider-root .MuiSlider-mark {
  height: 20px;
  background: #E3EBFA;
  opacity: 0.4;
}
.settings .MuiSlider-root .MuiSlider-markLabel {
  top: 32px;
  font-size: 12px;
  color: #9AABD2;
}
.settings .MuiSlider-root .MuiSlider-markLabelActive {
  color: #4E6496;
}
.settings .MuiSlider-root .MuiSlider-valueLabel {
  color: #00CE9C;
}
.settings .MuiSlider-root .MuiSlider-thumb {
  border-radius: 0px;
  margin: 0px;
  margin-left: -3px;
  height: 20px;
  width: 6px;
}
.settings .MuiSlider-root .MuiSlider-thumb .PrivateValueLabel-offset-17 {
  left: calc(-50% + -10px);
}
.settings .slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px 0px 8px 0px;
}
.settings .slider .units {
  font-size: 12px;
  width: 50px;
  margin: -2px 0px -5px 0px;
}
.settings p, .settings .setting-title {
  flex: 1;
  width: 80px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0px 10px 0px 0px;
}

/*------ PRICE ------*/
.price .final-price {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
}
.price .final-price .prices {
  display: flex;
}
.price .final-price .prices div {
  display: flex;
  align-items: baseline;
  margin: 0px 0px 12px 20px;
}
.price .final-price .prices div .price {
  margin-left: 2px;
  margin-bottom: 0px;
  font-size: 14px;
  color: #9AABD2;
}
.price .final-price p {
  margin: 0px;
  text-align: left;
}
.price .price-note {
  text-align: left;
  font-size: 12px;
}

/*------ ERROR ------*/
.error-banner {
  background: #fabd23;
  color: white;
  padding: 6px 0px;
}

/*------ SUBMIT ------*/
.submit-btn, .running-btn {
  cursor: pointer;
  width: auto;
  padding: 18px;
  background-image: linear-gradient(45deg, #3A6EFF, #42BEFF, #00CBE5, #00E4B2);
  color: white;
  font-size: 20px;
  font-weight: 500;
  background-size: 300% 100%;
  transition: all 0.2s ease-in-out;
}
.submit-btn:hover, .running-btn:hover {
  background-position: 100% 0;
  transition: all 0.2s ease-in-out;
}
.submit-btn .spinner, .running-btn .spinner {
  margin: 0px auto;
}
.submit-btn .spinner .bounce1, .submit-btn .spinner .bounce2, .submit-btn .spinner .bounce3, .running-btn .spinner .bounce1, .running-btn .spinner .bounce2, .running-btn .spinner .bounce3 {
  background-color: white;
}

.running-btn {
  background-image: linear-gradient(45deg, #00E4B2, #00CBE5, #42BEFF, #3A6EFF);
}

/*------ PROGRESS BAR ------*/
.progress {
  cursor: default;
  display: flex;
  height: 20px;
  font-size: 13px;
  overflow: hidden;
  border-radius: 2px 2px 0px 0px;
  margin: 0px -100px 0px -100px;
}
.progress .status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  background: #007bff;
  color: white;
}
.progress .progress-bg {
  display: flex;
  height: 20px;
  width: 100%;
  background-color: #e9ecef;
}
.progress .progress-bg .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease;
}
.progress .progress-bg .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

/*------ TERMINAL ------*/
.terminal {
  margin: 0px -100px 20px -100px;
}
.terminal .terminal-window {
  width: 660px;
  height: 380px;
  background: #03071D;
  z-index: 3;
  border-radius: 0px 0px 6px 6px;
  color: white;
  overflow-y: scroll;
  text-align: left;
  padding: 20px 22px;
}
.terminal .terminal-window .terminal-text {
  height: 20px;
  word-wrap: break-word;
}

/*------ LOADING ------*/
.spinner {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  margin: 0px 4px;
  background-color: #4E6496;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.cancel-run-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-run-modal .modal:focus {
  outline: none;
}
.cancel-run-modal .modal-card {
  text-align: center;
  background: white;
  padding: 40px 50px;
  border-radius: 8px;
  color: #7280A0;
  font-family: "IBM Plex Sans", sans-serif;
}
.cancel-run-modal .modal-card h2 {
  margin: 0px;
  color: #505C78;
}
.cancel-run-modal .modal-card p {
  margin: 12px 0px 24px 0px;
}
.cancel-run-modal .modal-card .buttons .btn {
  cursor: pointer;
  padding: 8px 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin: 0px 4px;
  border: none;
  border-radius: 4px;
  color: white;
}
.cancel-run-modal .modal-card .buttons .confirm {
  background: #4979FC;
  transition: 0.1s;
}
.cancel-run-modal .modal-card .buttons .confirm:hover {
  background: #2052D9;
  transform: translateY(-1px);
}
.cancel-run-modal .modal-card .buttons .cancel {
  background: #C3DDFF;
  transition: 0.1s;
}
.cancel-run-modal .modal-card .buttons .cancel:hover {
  background: #A2C9FF;
  transform: translateY(-1px);
}

/*# sourceMappingURL=App.css.map */
